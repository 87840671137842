import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import socketio from 'socket.io-client'
import PropTypes from 'prop-types'

const GlobalContext = createContext({})

export const useGlobalContext = () => {
  return useContext(GlobalContext)
}

export const GlobalContextProvider = ({ children }) => {
  const socketRef = useRef(null)

  const [globalState, globalDispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_DATA':
          return { ...state, ...action.payload }
        default:
          return state
      }
    },
    {
      tickets: [],
      ticketIndex: null,
      callcenterBreaks: [],
      inCallCenterBreak: false,
      callCenterBreakCod: null,
      callCenterBreakStart: null,
      callCenterLogin: false,
      callCenterBreakConfig: [],
      dominio: null,
      user_basix: null,
    },
  )
  const stateRef = useRef(globalState)

  const { dominio, user_basix } = globalState

  useEffect(() => {
    const userWebsocket = localStorage.getItem('contact-user-websocket')
    const domainioWebsocket = localStorage.getItem('contact-dominio-websocket')

    globalDispatch({
      type: 'SET_DATA',
      payload: {
        dominio: domainioWebsocket,
        user_basix: userWebsocket,
      },
    })
  }, [])

  useEffect(() => {
    console.log(globalState)
    stateRef.current = globalState
  }, [globalState])

  useEffect(() => {
    if (!dominio || !user_basix) {
      if (socketRef.current) {
        socketRef.current.disconnect()
        socketRef.current = null
      }

      return
    }

    if (!socketRef.current) {
      const tempSocket = socketio('https://devweb.cloudcom.com.br')
      socketRef.current = tempSocket

      tempSocket.on(`${dominio}-${user_basix}`, (data) => {
        globalDispatch({
          type: 'SET_DATA',
          payload: {
            tickets: [...stateRef.current.tickets, data], // Usa o estado mais recente
            ticketIndex: 0,
          },
        })
      })

      tempSocket.connect()
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect()
        socketRef.current = null
      }
    }
  }, [dominio, user_basix])

  return (
    <GlobalContext.Provider value={{ globalState, globalDispatch }}>
      {children}
    </GlobalContext.Provider>
  )
}

GlobalContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
