import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Card, Button, Icon, FormField, Select } from 'semantic-ui-react'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import withReactContent from 'sweetalert2-react-content'
import {
  loadCustomParamRequest,
  deleteCustomParamRequest,
  addCustomParamTemplateRequest,
} from '~/store/modules/contato/actions'

import { Container } from './styles'
import api from '../../services/api'

export default function TemplateFields() {
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.user.profile)
  const contato = useSelector((state) => state.contato)

  const [opcaoSelecionada, setOpcaoSelecionada] = useState(0)

  useEffect(() => {
    dispatch(loadCustomParamRequest(profile.id_dominio))
  }, [dispatch, profile.id_dominio])

  async function handleAddField() {
    const { value: formValues } = await Swal.fire({
      title: 'Adicionar novo campo',
      html: `
      <div class="ui form">
        <div class="field">
          <label>Nome do Campo</label>
          <input id="swal-input1" type="text" class="swal2-input" placeholder="Digite o nome do campo">
        </div>
        <div class="field">
          <label>Tipo de Campo</label>
          <select id="swal-input2" class="ui fluid selection dropdown swal2-select">
            <option value="1">Texto</option>
            <option value="2">Caixa de Seleção</option>
          </select>
        </div>
      </div>

      `,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById('swal-input1').value,
          document.getElementById('swal-input2').value,
        ]
      },
    })

    if (!formValues) return

    const nomeCampo = formValues[0]
    const tipoCampo = formValues[1]

    const retorno = contato.contact.ContactFields.filter(
      (item) => item.nome_campo === nomeCampo,
    )
    if (retorno.length > 0) {
      const MySwal = withReactContent(Swal)
      return MySwal.fire({
        icon: 'error',
        title: 'Erro ao adicionar',
        text: 'Este campo já existe !',
      })
    }

    if (nomeCampo && tipoCampo) {
      dispatch(
        addCustomParamTemplateRequest({
          name: nomeCampo,
          id_dominio: profile.id_dominio,
          tipo: tipoCampo,
        }),
      )
    }
    return false
  }

  function handleDeleteField(nome, id) {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: `Apagar campo: ${nome}`,
      text: 'Tem certeza que deseja deletar ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        dispatch(deleteCustomParamRequest(id))
        // setContactFields(
        //   contactFields.filter(item => item.nome_campo !== nome)
        // );
      }
      return false
    })
  }

  function handleAddSelectOption(id) {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: 'Adicionar nova opção',
      input: 'text',
      inputPlaceholder: 'Digite o nome da opção',
      showCancelButton: true,
      confirmButtonText: 'Adicionar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        api
          .post(`/domain/${profile.id_dominio}/templatefields/${id}/values`, {
            valor: result.value,
          })
          .then((response) => {
            toast.success('Item adicionado com sucesso')
            dispatch(loadCustomParamRequest(profile.id_dominio))
          })
          .catch((error) => {
            toast.error(`${error.error}`)
          })
      }
    })
  }

  function handleRmSelectOption(idField, id, texto) {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: `Apagar opção: ${texto}`,
      text: 'Tem certeza que deseja deletar ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        api
          .delete(
            `/domain/${profile.id_dominio}/templatefields/${idField}/values/${id}`,
          )
          .then((response) => {
            toast.success('Item removido com sucesso')
            dispatch(loadCustomParamRequest(profile.id_dominio))
          })
          .catch((error) => {
            toast.error(`${error.error}`)
          })
      }
      return false
    })
  }

  return (
    <Container>
      <Card>
        <Card.Content>
          <Button
            icon
            labelPosition="left"
            primary
            size="small"
            onClick={() => handleAddField()}
          >
            <Icon name="plus" /> Adicionar campo
          </Button>
        </Card.Content>
        <Card.Content>
          <Form>
            <Form.Group className="fields_personalizados">
              {contato.contact.ContactFields?.map((field) => {
                if (field.tipo === 1) {
                  return (
                    <Form.Input
                      key={field.nome_campo}
                      name={field.nome_campo}
                      fluid
                      label={field.nome_campo}
                      placeholder={field.nome_campo}
                      value={'texto'}
                      onChange={() => {}}
                      action={{
                        type: 'button',
                        color: 'red',
                        icon: 'delete',
                        onClick: () => {
                          handleDeleteField(field.nome_campo, field.id)
                        },
                      }}
                    />
                  )
                }
                if (field.tipo === 2) {
                  return (
                    <div className="select-button">
                      <FormField
                        className="field2"
                        control={Select}
                        fluid
                        label={field.nome_campo}
                        options={[
                          ...field.template_fields_values.map((item) => {
                            return {
                              key: item.id,
                              text: item.valor,
                              value: item.id,
                            }
                          }),
                        ]}
                        value={opcaoSelecionada}
                        onChange={(e, { value }) => setOpcaoSelecionada(value)}
                        placeholder={'campo de seleção'}
                      />

                      <div className="buttons">
                        <Button
                          icon
                          onClick={() => {
                            handleAddSelectOption(field.id)
                          }}
                        >
                          <Icon name="plus" />
                        </Button>

                        {opcaoSelecionada > 0 &&
                          field.template_fields_values.find(
                            (item) => item.id === opcaoSelecionada,
                          ) && (
                            <Button
                              icon
                              onClick={() => {
                                handleRmSelectOption(
                                  field.id,
                                  opcaoSelecionada,
                                  field.template_fields_values.find(
                                    (item) => item.id === opcaoSelecionada,
                                  ).valor,
                                )
                              }}
                            >
                              <Icon name="minus" />
                            </Button>
                          )}

                        <Button
                          icon
                          color="red"
                          onClick={() => {
                            handleDeleteField(field.nome_campo, field.id)
                          }}
                        >
                          <Icon name="delete" />
                        </Button>
                      </div>
                    </div>
                  )
                }
              })}
            </Form.Group>
          </Form>
        </Card.Content>
      </Card>
    </Container>
  )
}
