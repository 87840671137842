import React, { useCallback, useEffect, useState } from 'react'
import { differenceInMilliseconds } from 'date-fns'
import { Menu, Button, Dropdown } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { differenceInSeconds } from 'date-fns'

import { BsListCheck, BsClockFill } from 'react-icons/bs'
import {
  // FaHome,
  FaSitemap,
  FaUsers,
  FaBook,
  FaThList,
  // FaPhone,
  FaUserCog,
  // FaPhoneAlt,
  FaEnvelopeOpenText,
} from 'react-icons/fa'
import logo from '~/assets/phone-book.png'
import history from '~/services/history'

import { signOut } from '~/store/modules/auth/actions'
import {
  loginRequest,
  logoutRequest,
  sairPausaRequest,
  getBreaksRequest,
  entrarPausaRequest,
  changeBreakStatusRequest,
  changeBreakStatusSuccess,
  changeLoginStatusRequest,
} from '~/store/modules/callcenter/actions'
import { MenuContainer } from './styles'
import { useGlobalContext } from '../../contexts/GlobalContext'
import { toast } from 'react-toastify'
import api from '~/services/api'
// import { padding } from 'polished'

// Função para formatar a diferença em mm:ss
function formatDuration(milliseconds) {
  const totalSeconds = Math.floor(milliseconds / 1000) // Converte para segundos
  const horas = Math.floor(totalSeconds / 3600) // Calcula as horas
  const minutos = Math.floor((totalSeconds % 3600) / 60) // Calcula os minutos
  // const minutes = Math.floor(totalSeconds / 60) // Calcula os minutos
  const seconds = totalSeconds % 60 // Calcula os segundos restantes

  // Formata para mm:ss (adiciona um zero à esquerda se necessário)
  return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}

export default function Header() {
  const { globalState, globalDispatch } = useGlobalContext()
  const {
    callCenterLogin,
    callcenterBreaks,
    inCallCenterBreak,
    callCenterBreakStart,
    callCenterBreakCod,
    callCenterBreakConfig,
  } = globalState

  const dispatch = useDispatch()
  const [currentPauseConfig, setCurrentPauseConfig] = useState([])
  const profile = useSelector((state) => state.user.profile)
  const callcenter = useSelector((state) => state.callcenter)
  const currentLocation = window.location.pathname

  // const [inicio, setInicio] = useState(new Date())
  const [agora, setAgora] = useState(new Date())
  const [tempo, setTempo] = useState('')

  const handleLogin = useCallback(async () => {
    try {
      await api.post('/callcenter/login', {
        user: profile.user_basix,
        domain: profile.dominio,
      })

      globalDispatch({
        type: 'SET_DATA',
        payload: {
          callCenterLogin: true,
          inCallCenterBreak: false,
          callCenterBreakStart: null,
        },
      })
      toast.success('Login realizado com sucesso no CallCenter')
    } catch (error) {
      toast.error('Erro ao fazer login no CallCenter')
      console.error('Erro ao fazer login no CallCenter:', error)
    }
  }, [profile, globalDispatch])

  const handleLogout = useCallback(async () => {
    try {
      await api.post('/callcenter/logout', {
        user: profile.user_basix,
        domain: profile.dominio,
      })

      globalDispatch({
        type: 'SET_DATA',
        payload: {
          callCenterLogin: false,
          inCallCenterBreak: false,
          callCenterBreakStart: null,
        },
      })
      toast.success('Logout realizado com sucesso no CallCenter')
    } catch (error) {
      toast.error('Erro ao fazer logout do CallCenter')
      console.error('Erro ao fazer logout do CallCenter:', error)
    }
  }, [profile, globalDispatch])

  const handleEntrarPausa = useCallback(
    async (cod) => {
      try {
        await api.post('/callcenter/pausa/entrar', {
          user: profile.user_basix,
          domain: profile.dominio,
          cod,
        })

        globalDispatch({
          type: 'SET_DATA',
          payload: {
            callCenterLogin: true,
            inCallCenterBreak: true,
            callCenterBreakCod: cod,
            callCenterBreakStart: new Date(),
          },
        })
        toast.success('Pausa iniciada com sucesso')
      } catch (error) {
        toast.error('Erro ao iniciar pausa')
        console.error('Erro ao iniciar pausa:', error)
      }
    },
    [profile, globalDispatch],
  )

  const handleSairPausa = useCallback(async () => {
    try {
      await api.post('/callcenter/pausa/sair', {
        user: profile.user_basix,
        domain: profile.dominio,
      })

      globalDispatch({
        type: 'SET_DATA',
        payload: {
          callCenterLogin: true,
          inCallCenterBreak: false,
          callCenterBreakCod: null,
          callCenterBreakStart: null,
        },
      })
      toast.success('Pausa encerrada com sucesso')
    } catch (error) {
      toast.error('Erro ao encerrar pausa')
      console.error('Erro ao encerrar pausa:', error)
    }
  }, [profile, globalDispatch])

  useEffect(() => {
    if (profile && profile.loginlogout === 1) {
      dispatch(
        getBreaksRequest({
          domain: profile.dominio,
          group: profile.callcenter_group,
        }),
      )
    }
  }, [dispatch, profile, profile.callcenter_group, profile.dominio])

  function handleSignOut() {
    globalDispatch({
      type: 'SET_DATA',
      payload: {
        tickets: [],
        ticketIndex: null,
        callCenterLogin: false,
        inCallCenterBreak: false,
        callCenterBreakCod: null,
        callCenterBreakStart: null,
        dominio: null,
        user_basix: null,
      },
    })
    localStorage.removeItem('contact-user-websocket')
    localStorage.removeItem('contact-dominio-websocket')

    dispatch(signOut())
  }

  useEffect(() => {
    const currentPause = callCenterBreakConfig.find(
      (item) =>
        Number.parseInt(item.INT_PAUSECODE, 10) ===
        Number.parseInt(callCenterBreakCod, 10),
    )

    if (currentPause) {
      const times = currentPause.VCH_PAUSETIMEOUTS.split(',')
      setCurrentPauseConfig([times[0], times[2], times[3]])
    }
  }, [callCenterBreakCod, callCenterBreakConfig])

  useEffect(() => {
    const updateTime = setInterval(() => {
      setAgora(new Date())
    }, 1000)
    return () => {
      clearInterval(updateTime)
    }
  }, [])

  useEffect(() => {
    setTempo(
      formatDuration(differenceInMilliseconds(agora, callCenterBreakStart)),
    )
  }, [agora, callCenterBreakStart])

  console.log({
    callCenterLogin,
    inCallCenterBreak,
    verde:
      differenceInSeconds(agora, callCenterBreakStart) <= currentPauseConfig[0],
    amarelo:
      differenceInSeconds(agora, callCenterBreakStart) >
        currentPauseConfig[0] &&
      differenceInSeconds(agora, callCenterBreakStart) <= currentPauseConfig[1],
    vermelho:
      differenceInSeconds(agora, callCenterBreakStart) > currentPauseConfig[1],
  })

  return (
    <MenuContainer>
      <Menu stackable>
        <Menu.Item>
          <img src={logo} alt="Logo Contact" />
        </Menu.Item>
        <Menu.Item
          name="Dashboard"
          active={currentLocation === '/dashboard'}
          onClick={() => history.push('/dashboard')}
        >
          <FaEnvelopeOpenText size={12} />
          Chamados Abertos
        </Menu.Item>
        {profile.tipo === 3 && (
          <Menu.Item
            name="Domínios"
            active={currentLocation === '/dominios'}
            onClick={() => history.push('/dominios')}
          >
            <FaSitemap />
            Dominíos
          </Menu.Item>
        )}
        {profile.tipo >= 2 && (
          <Menu.Item
            name="Usuários"
            active={currentLocation.indexOf('/usuarios') === 0}
            onClick={() => history.push('/usuarios')}
          >
            <FaUsers />
            Usuários
          </Menu.Item>
        )}
        <Menu.Item
          name="Chamados"
          active={currentLocation.indexOf('/chamados') === 0}
          onClick={() => history.push('/chamados')}
        >
          <FaBook />
          Chamados
        </Menu.Item>
        <Menu.Item
          name="Contatos"
          active={currentLocation.indexOf('/contato') === 0}
          onClick={() => history.push('/contatos')}
        >
          <FaThList />
          Contatos
        </Menu.Item>
        <Menu.Item
          name="Contatos"
          active={currentLocation.indexOf('/tabulacoes') === 0}
          onClick={() => history.push('/tabulacoes')}
        >
          <BsListCheck />
          Tabulações
        </Menu.Item>
        {/* {profile.tipo >= 2 && (
          <Menu.Item
            name="Mailings"
            active={currentLocation.indexOf('/mailings') === 0}
            onClick={() => history.push('/mailings')}
          >
            <FaThList />
            Mailings
          </Menu.Item>
        )} */}
        {/* {profile.dominio === 'cloud.cloudcom.com.br' && (
          <Menu.Item
            name="WebPhone"
            active={currentLocation.indexOf('/webphone') === 0}
            onClick={() => history.push('/webphone')}
          >
            <FaPhoneAlt />
            WebPhone
          </Menu.Item>
        )} */}
        {/* {profile.dominio === 'cloud.cloudcom.com.br' && ( */}
        {/* {profile.dominio === 'grupoalliar.cloudcom.com.br' && (
          <Menu.Item
            name="Ativo"
            active={currentLocation.indexOf('/ativo') === 0}
            onClick={() => history.push('/ativo')}
          >
            <FaPhone />
            Ativo
          </Menu.Item>
        )} */}
        {profile.loginlogout === 1 && (
          <Dropdown
            text="CallCenter"
            icon="call square"
            labeled
            id="dropdown-callcenter"
            className={
              callCenterLogin
                ? 'link item left_icon green_color'
                : 'link item left_icon red_color'
            }
          >
            <Dropdown.Menu>
              {!callCenterLogin && (
                <Dropdown.Item
                  icon="sign-in"
                  className="green_color"
                  text="Logar"
                  onClick={() => {
                    handleLogin()
                  }}
                />
              )}

              <Dropdown.Item
                icon="sign-out"
                className="red_color"
                text="Deslogar"
                onClick={() => {
                  handleLogout()
                }}
              />
              <Dropdown.Divider />
              <Dropdown.Header>Pausas</Dropdown.Header>
              {!inCallCenterBreak && (
                <Dropdown.Item>
                  <Dropdown
                    icon="phone"
                    className="red_color left_icon"
                    text="Entrar"
                  >
                    <Dropdown.Menu>
                      {callcenterBreaks?.map((item) => {
                        return (
                          <Dropdown.Item
                            key={item.codigo}
                            onClick={() => {
                              handleEntrarPausa(item.codigo)
                            }}
                          >
                            {item.nome}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Dropdown.Item>
              )}
              {inCallCenterBreak && (
                <Dropdown.Item
                  icon="phone"
                  className="green_color"
                  text="Sair da pausa"
                  onClick={() => {
                    handleSairPausa()
                  }}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {callCenterLogin && inCallCenterBreak && (
          <>
            {differenceInSeconds(agora, callCenterBreakStart) <=
              currentPauseConfig[0] && (
              <Menu.Item
                name="Contatos"
                // active={currentLocation.indexOf('/tabulacoes') === 0}
                onClick={() => {
                  document.getElementById('dropdown-callcenter').click()
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '2px',
                  gap: '6px',
                  color: '#FFFFFF',
                  fontWeight: 'bold',
                  border: '2px solid #006400',
                  borderRadius: '5px',
                  backgroundColor: '#32CD32',
                  textAlign: 'center',
                }}
              >
                <BsClockFill color={'#FFFFFF'} />
                {
                  callcenterBreaks.find(
                    (item) =>
                      Number.parseInt(item.codigo, 10) ===
                      Number.parseInt(callCenterBreakCod, 10),
                  )?.nome
                }
                <br />
                {tempo}
              </Menu.Item>
            )}
            {differenceInSeconds(agora, callCenterBreakStart) >
              currentPauseConfig[0] &&
              differenceInSeconds(agora, callCenterBreakStart) <=
                currentPauseConfig[1] && (
                <Menu.Item
                  name="Contatos"
                  // active={currentLocation.indexOf('/tabulacoes') === 0}
                  onClick={() => {
                    document.getElementById('dropdown-callcenter').click()
                  }}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '2px',
                    gap: '6px',
                    color: '#000000',
                    fontWeight: 'bold',
                    border: '2px solid #DAA520',
                    borderRadius: '5px',
                    backgroundColor: '#FFD700',
                    textAlign: 'center',
                  }}
                >
                  <BsClockFill color={'#000000'} />
                  {
                    callcenterBreaks.find(
                      (item) =>
                        Number.parseInt(item.codigo, 10) ===
                        Number.parseInt(callCenterBreakCod, 10),
                    )?.nome
                  }
                  <br />
                  {tempo}
                </Menu.Item>
              )}

            {differenceInSeconds(agora, callCenterBreakStart) >
              currentPauseConfig[1] && (
              <Menu.Item
                name="Contatos"
                // active={currentLocation.indexOf('/tabulacoes') === 0}
                onClick={() => {
                  document.getElementById('dropdown-callcenter').click()
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '2px',
                  gap: '6px',
                  color: '#FFFFFF',
                  fontWeight: 'bold',
                  border: '2px solid #8B0000',
                  borderRadius: '5px',
                  backgroundColor: '#FF4500',
                  textAlign: 'center',
                }}
              >
                <BsClockFill color={'#FFFFFF'} />
                {
                  callcenterBreaks.find(
                    (item) =>
                      Number.parseInt(item.codigo, 10) ===
                      Number.parseInt(callCenterBreakCod, 10),
                  )?.nome
                }
                <br />
                {tempo}
              </Menu.Item>
            )}
          </>
        )}
        <Menu.Menu position="right">
          {/* <Menu.Item>
            <Button.Group size="mini">
              <Button color="olive">Logar</Button>
              <Button.Or />
              <Button color="black">Deslogar</Button>
            </Button.Group>
          </Menu.Item> */}

          <Menu.Item
            name="Contatos"
            active={currentLocation.indexOf('/profile') === 0}
            onClick={() => history.push('/profile')}
          >
            <FaUserCog />
            {profile.nome}
          </Menu.Item>
          <Menu.Item>
            <Button color="red" onClick={() => handleSignOut()}>
              Logout
            </Button>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </MenuContainer>
  )
}
