import produce from 'immer'

const INITIAL_STATE = {
  login: false,
  pause: false,
  breaks: [],
  pauseStart: null,
  pauseCod: null,
  green: 0,
  yellow: 0,
  red: 0,
}

// biome-ignore lint/style/useDefaultParameterLast: <explanation>
export default function callcenter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@callcenter/LOGIN_SUCCESS':
      return produce(state, (draft) => {
        draft.login = true
        draft.pause = false
      })
    case '@callcenter/LOGIN_FAILURE':
      return produce(state, (draft) => {
        draft.login = false
        draft.pause = false
      })
    case '@callcenter/LOGOUT_SUCCESS':
      return produce(state, (draft) => {
        draft.login = false
        draft.pause = false
      })
    case '@callcenter/LOGOUT_FAILURE':
      return produce(state, (draft) => {
        draft.login = true
        draft.pause = false
      })
    case '@callcenter/ENTRAR_PAUSA_SUCCESS':
      return produce(state, (draft) => {
        draft.login = true
        draft.pause = true
      })
    case '@callcenter/ENTRAR_PAUSA_FAILURE':
      return produce(state, (draft) => {
        draft.login = true
        draft.pause = false
      })
    case '@callcenter/SAIR_PAUSA_SUCCESS':
      return produce(state, (draft) => {
        draft.login = true
        draft.pause = false
      })
    case '@callcenter/SAIR_PAUSA_FAILURE':
      return produce(state, (draft) => {
        draft.login = true
        draft.pause = true
      })
    case '@callcenter/GET_BREAKS_SUCCESS':
      return produce(state, (draft) => {
        draft.breaks = action.payload.breaks
      })
    case '@callcenter/GET_BREAKS_FAILURE':
      return produce(state, (draft) => {
        draft.breaks = []
      })

    // Se houver sucesso na busca da Pausa, atualiza o estado da Pausa
    case '@callcenter/CHANGE_BREAK_STATUS_SUCCESS':
      return produce(state, (draft) => {
        draft.pause = action.payload.pause
        draft.pauseStart = action.payload.pauseStart
        draft.pauseCod = action.payload.pauseCod
        draft.green = 60
        draft.yellow = 120
        draft.red = 180
      })

    case '@callcenter/CHANGE_LOGIN_STATUS_SUCCESS':
      return produce(state, (draft) => {
        draft.login = action.payload.login
      })
    case '@callcenter/CHANGE_LOGIN_STATUS_FAILURE':
      return produce(state, (draft) => {
        draft.login = false
      })

    default:
      return state
  }
}
