import { takeLatest, call, put, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import history from '~/services/history'
import api from '~/services/api'

import { signInSucess, signInFailure } from './actions'
// import { useGlobalContext } from '../../../contexts/GlobalContext'

export function* signIn({ payload }) {
  try {
    const { email, senha } = payload

    const response = yield call(api.post, 'sessions', {
      email,
      senha,
    })

    const { token, user } = response.data
    // const { globalDispatch } = useGlobalContext()
    // globalDispatch({
    //   type: 'SET_DATA',
    //   payload: {
    //     dominio: user.dominio,
    //     user_basix: user.user_basix,
    //   },
    // })

    api.defaults.headers.Authorization = `Bearer ${token}`

    yield put(signInSucess(token, user))

    history.push('/dashboard')
  } catch (error) {
    if (error.response.data.error) {
      toast.error(error.response.data.error)
    } else {
      toast.error('Falha na autenticação verifique seus dados')
    }
    yield put(signInFailure())
  }
}

export function setToken({ payload }) {
  if (!payload) return

  const { token } = payload.auth

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`
  }
}

export function signOut() {
  history.push('/')
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
])
