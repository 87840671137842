import React, { useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import api from '~/services/api'
import Tabs from './Tabs_Semantic'
import { Container } from './styles'
import { useGlobalContext } from '../../contexts/GlobalContext'

export default function Dashboard() {
  const { globalState, globalDispatch } = useGlobalContext()
  const profile = useSelector((state) => state.user.profile)

  const fetchTabulations = useCallback(async () => {
    try {
      const response = await api.get(`/tabulacao/domain/${profile.id_dominio}`)
      globalDispatch({
        type: 'SET_DATA',
        payload: {
          tabulacoes: response.data,
        },
      })
    } catch (error) {
      console.error('Error ao aobter tabulações:', error)
    }
  }, [globalDispatch, profile])

  const fetchOpenTickets = useCallback(async () => {
    try {
      const response = await api.get('/tickets/open')
      globalDispatch({
        type: 'SET_DATA',
        payload: {
          tickets: response.data,
        },
      })
    } catch (error) {
      console.error('Error ao obter tickets:', error)
    }
  }, [globalDispatch])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (globalState.tickets.length === 0) {
      fetchOpenTickets()
    }
    fetchTabulations()
  }, [fetchOpenTickets, fetchTabulations])

  return (
    <Container>
      <Tabs />
    </Container>
  )
}
