import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownMenu, Menu } from 'semantic-ui-react'

import CustomDropdownItem from './dropdownItem'
import { Container } from './style'
import { useGlobalContext } from '../../contexts/GlobalContext'

export default function Tabulacao({ id_ticket, id_tabulacao }) {
  const { globalState, globalDispatch } = useGlobalContext()
  const { tabulacoes, tickets } = globalState

  const [tabulacao, setTabulacao] = useState(id_tabulacao)

  function findItemNameById(id, estrutura) {
    for (let i = 0; i < estrutura.length; i++) {
      if (estrutura[i].id === id) {
        return estrutura[i].nome
      }

      if (estrutura[i].tabulations.length > 0) {
        const result = findItemNameById(id, estrutura[i].tabulations)
        if (result) return result
      }
    }
  }

  useEffect(() => {
    setTabulacao(id_tabulacao)
  }, [id_tabulacao])

  const setTabulacaoLocal = (id) => {
    setTabulacao(id)

    const ticketsAlterados = tickets.map((item) => {
      if (item.id === id_ticket) {
        return { ...item, fk_id_tabulacao: id }
      }
      return item
    })

    globalDispatch({
      type: 'SET_DATA',
      payload: {
        tickets: ticketsAlterados,
      },
    })
  }

  return (
    <Container warning={tabulacao === 'Selecione uma tabulação'}>
      <Menu compact>
        <Dropdown
          text={
            findItemNameById(tabulacao, tabulacoes) ?? 'Selecione uma tabulação'
          }
          pointing
          className="link item titulo"
        >
          <DropdownMenu>
            {tabulacoes.map((item) => {
              return (
                <CustomDropdownItem
                  key={item.id}
                  estrutura={item.tabulations}
                  item={item}
                  setTabulacao={setTabulacaoLocal}
                />
              )
            })}
          </DropdownMenu>
        </Dropdown>
      </Menu>
    </Container>
  )
}
