import React from 'react'
import { Tab } from 'semantic-ui-react'

import { Container } from './styles'
import Form from './Form'
import { useGlobalContext } from '../../../contexts/GlobalContext'

export default function TabsSemantic() {
  const { globalState, globalDispatch } = useGlobalContext()
  const { tickets, ticketIndex } = globalState

  const handleTabChange = (e, { activeIndex }) => {
    globalDispatch({
      type: 'SET_DATA',
      payload: {
        ticketIndex: activeIndex,
      },
    })
  }

  return (
    <Container>
      <Tab
        className="tabs"
        menu={{ pointing: true }}
        activeIndex={ticketIndex}
        onTabChange={handleTabChange}
        panes={tickets
          .sort((a, b) => {
            if (a.id > b.id) return -1
            if (a.id < b.id) return 1
            return 0
          })
          .map((chamado) => {
            return {
              menuItem: chamado.id.toString(),
              render: () => {
                return <Form chamado={chamado} />
              },
            }
          })}
      />
    </Container>
  )
}
