import React from 'react'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import './config/ReactotronConfig'

import Routes from './routes'
import history from './services/history'

import { store, persistor } from './store'

import 'semantic-ui-css/semantic.min.css'
import GlobalStyle from './styles/global'
// import Websocket from './services/websocket'
import { GlobalContextProvider } from './contexts/GlobalContext'

function App() {
  return (
    <>
      <GlobalContextProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Router history={history}>
              <>
                {/* <Websocket /> */}
                <Routes />
                <GlobalStyle />
              </>
            </Router>
          </PersistGate>
        </Provider>
        <ToastContainer autoClose={3000} />
      </GlobalContextProvider>
    </>
  )
}

export default App
