import { takeLatest, put, call, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { parse } from 'date-fns'

import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  sairPausaSuccess,
  sairPausaFailure,
  getBreaksSuccess,
  getBreaksFailure,
  entrarPausaSuccess,
  entrarPausaFailure,
  changeBreakStatusSuccess,
  changeBreakStatusFailure,
  changeLoginStatusSuccess,
  changeLoginStatusFailure,
} from './actions'
import api from '~/services/api'
import apiV3 from '~/services/api_v3'

export function* login({ payload }) {
  try {
    const { user, domain } = payload
    const { data } = yield call(api.post, '/callcenter/login', {
      user,
      domain,
    })
    yield put(loginSuccess(data))
    toast.success(data.message)
  } catch (error) {
    yield put(loginFailure({ error: 'Falha ao efetuar login' }))
    toast.error('Falha ao efetuar login')
  }
}

export function* logout({ payload }) {
  try {
    const { user, domain } = payload
    const { data } = yield call(api.post, '/callcenter/logout', {
      user,
      domain,
    })
    yield put(logoutSuccess(data))
    toast.success(data.message)
  } catch (error) {
    yield put(logoutFailure({ error: 'Falha ao efetuar logout' }))
    toast.error('Falha ao efetuar logout')
  }
}

export function* sairPausa({ payload }) {
  try {
    const { user, domain } = payload
    const { data } = yield call(api.post, '/callcenter/pausa/sair', {
      user,
      domain,
    })
    yield put(sairPausaSuccess(data))
    toast.success(data.message)
  } catch (error) {
    yield put(sairPausaFailure({ error: 'Falha ao sair da pausa' }))
    toast.error('Falha ao sair da pausa')
  }
}

export function* entrarPausa({ payload }) {
  try {
    const { user, domain, cod } = payload
    const { data } = yield call(api.post, '/callcenter/pausa/entrar', {
      user,
      domain,
      cod,
    })
    yield put(entrarPausaSuccess(data))

    yield put(
      changeBreakStatusSuccess({
        pause: true,
        pauseStart: new Date(),
        pauseCod: cod,
      }),
    )
    toast.success(data.message)
  } catch (error) {
    yield put(entrarPausaFailure({ error: 'Falha ao entrar em pausa' }))
    toast.error('Falha ao entrar em pausa')
  }
}

export function* getBreaks({ payload }) {
  try {
    const { domain, group } = payload
    const { data } = yield call(api.get, '/callcenter/pausas', {
      params: { domain, group },
    })
    yield put(getBreaksSuccess(data))
    toast.success('Pausas atualizadas')
  } catch (error) {
    yield put(getBreaksFailure({ error: 'Falha ao buscar pausas' }))
    toast.error('Falha ao buscar pausas')
  }
}

export function* changeBreakStatus({ payload }) {
  try {
    const { user, domain } = payload
    const { data } = yield call(
      apiV3.get,
      `/contact/domain/${domain}/user/${user}/isonpause`,
      {
        user,
        domain,
      },
    )

    yield put(
      changeBreakStatusSuccess({
        pause: true,
        pauseStart: parse(data.LAST_PAUSE, 'dd-MM-yyyy HH:mm:ss', new Date()),
        pauseCod: data.INT_PAUSE_CODE,
      }),
    )

    toast.success(`Agente em pausa - ${data.INT_PAUSE_CODE}`)
  } catch (error) {
    if (error.response.status === 404) {
      yield put(
        changeBreakStatusSuccess({
          pause: false,
          pauseStart: null,
          pauseCod: null,
        }),
      )
      toast.success('Agente fora de pausa')
      return
    }

    yield put(
      changeBreakStatusFailure({ error: 'Falha ao buscar pausa do PBX' }),
    )
    toast.error('Falha ao buscar pausa do PBX')
  }
}

export function* verifiyLoginStatus({ payload }) {
  try {
    const { user, domain } = payload
    const { data } = yield call(
      apiV3.get,
      `/contact/domain/${domain}/user/${user}/islogged`,
    )
    if (data.DTM_LOGIN_TIME && data.DTM_LOGOUT_TIME) {
      yield put(
        changeLoginStatusSuccess({
          login: false,
        }),
      )
    }
    if (data.DTM_LOGIN_TIME && !data.DTM_LOGOUT_TIME) {
      yield put(
        changeLoginStatusSuccess({
          login: true,
        }),
      )
    }
  } catch (error) {
    yield put(
      changeLoginStatusFailure({ error: 'Falha ao verificar status de login' }),
    )
    toast.error('Falha ao verificar status de login')
  }
}

export default all([
  takeLatest('@callcenter/LOGIN_REQUEST', login),
  takeLatest('@callcenter/LOGOUT_REQUEST', logout),
  takeLatest('@callcenter/SAIR_PAUSA_REQUEST', sairPausa),
  takeLatest('@callcenter/GET_BREAKS_REQUEST', getBreaks),
  takeLatest('@callcenter/ENTRAR_PAUSA_REQUEST', entrarPausa),
  takeLatest('@callcenter/CHANGE_BREAK_STATUS_REQUEST', changeBreakStatus),
  takeLatest('@callcenter/CHANGE_LOGIN_STATUS_REQUEST', verifiyLoginStatus),
])
